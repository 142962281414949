import React, { useContext, useEffect } from "react";
import Layout from "../layouts/layout";

import DynamicComponent from "../components";

import useStoryblok from "../lib/storyblok";

import PageContext from "../context/PageContext";

interface PageProps {
  pageContext: any;
  location: Location;
}

const StoryblokEntryHooks: React.FC<PageProps> = ({
  pageContext,
  location,
}) => {
  const pageCtx = useContext(PageContext);

  useEffect(() => {
    pageCtx.set && pageCtx.set({ ...pageContext });
  }, [pageContext]);

  const { alternatives } = pageContext;
  let { story, settings } = pageContext;
  ({ story, settings } = useStoryblok(location, story, settings));

  if (typeof settings.content === "string") {
    settings.content = JSON.parse(settings.content);
  }

  if (typeof story.content === "string") {
    story.content = JSON.parse(story.content);
  }

  return (
    <Layout settings={settings}>
      {story && (
        <DynamicComponent
          key={story.content._uid}
          content={story.content}
          blok={story.content}
          settings={settings}
          story={story}
          alternates={alternatives}
        />
      )}
    </Layout>
  );
};

export default StoryblokEntryHooks;
